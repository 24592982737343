import React, { useState } from "react";
import {
  LabelText,
  ButtonInput,
  TextInput,
  CheckboxInput,
  RadioInput,
  SelectInput,
} from "@emerson/dynamic-ui-public";
import "./serviceRequestDetails.css";
import calender from "../../assets/calendar.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import useOnScreen from "../../customHooks/UseOnScreen";

import { useEffect } from "react";
const ServiceRequestDetails = (props) => {
  const {
    setActiveInStep,
    serviceName,
    serviceTypeName,
    userSiteList,
    setSelectedSite,
    selectedSite,
    setPrefSerDate,
    setAddDetails,
    setEndUser,
    endUser,
    setEndUserSiteAddress,
    endUserSiteAddress,
    setStepMemoize,
    setSysInfoDetails,
    stepMemoize,
    setServiceName,
  } = props;
  const [startDate, setDate] = useState(new Date());
  const [errorSiteNotFoundMsg, setErrorSiteNotFoundMsg] = useState("");

  const selectDateHandler = (d) => {
    setDate(d);
  };

  const  setErrorMessage = () => {
    setErrorSiteNotFoundMsg("Could not continue with the selected site. Please select a valid site.");
    setTimeout(() => {
      setErrorSiteNotFoundMsg("");
    }, 5000)
  }

  const today = new Date();

  const siteOptions = (ipArr) => {
    return ipArr?.map((customer, ind) => {
      return {
        greyedOut: false,
        label: customer.customer_name,
        title: customer,
        value: customer.customer_name,
      };
    });
  };
  // visibility check
  const handleVisibilityChange = () => {
    setStepMemoize(3);
    serviceTypeName === "Return A Product" && setServiceName("");
  };
  const ref = useOnScreen(handleVisibilityChange);
  //visibilty check
  useEffect(() => {
    setPrefSerDate(startDate.toDateString());
  }, [startDate]);
  useEffect(() => {
    serviceTypeName === "Return A Product" && setActiveInStep(3);
    if (stepMemoize === 5) {
      setSysInfoDetails("");
      setActiveInStep(3);
    }
  }, []);
  useEffect(() => {
    // selectedSite === "" ? setStepperDisable(true) : setStepperDisable(false);
  }, [selectedSite]);
  return (
    <div className="selectFieldService commonDisplaysize" ref={ref}>
      <br />
      <br />
      <LabelText text={"Service Request Information"} textClass="ddl-text--h3" />
      <br />
      <p>
        Tell us more about your needs.
        <br />
        <br />
      </p>
     <div className="ServiceRequestText">
        {serviceName === "Troubleshooting" && (
          <RadioInput
            label="Remote Access Availability"
            onChange={() => {}}
            options={[
              {
                label: "Yes",
                value: "yes",
              },
              {
                label: "No",
                value: "no",
              },
            ]}
            required
            size="small"
          />
        )}
        {serviceName === "Training" && (
          <CheckboxInput
            label="Training Type"
            onChange={() => {}}
            options={[
              {
                label: "Emerson Office",
                value: "emerson_office",
              },
              {
                label: "Site Based",
                value: "site_based",
              },
              {
                label: "Digital Class",
                value: "digital_class",
              },
            ]}
            required
            size="small"
          />
        )}
        <div className="SiteServiceDate">
          <SelectInput
            label="Select a Site"
            labelClass="app-content-label"
            name="critical"
            required
            value={selectedSite?.customer_name}
            onChange={(o, s, m, h) => {
              setSelectedSite(o.title);
            }}
            options={siteOptions(
              userSiteList.length === 0
                ? [{ customer_name: "SITE NOT FOUND" }]
                : [
                    ...userSiteList?.data
                      ?.filter((item, index, self) => {
                        return (
                          index ===
                          self.findIndex(
                            (t) =>
                              t.gdm_customer_number ===
                                item.gdm_customer_number &&
                              `${t.ebs_customer_name || ""}${
                                t.oracle_customer_acc_number
                                  ? ` - ${t.oracle_customer_acc_number}`
                                  : ""
                              }` ===
                                `${item.ebs_customer_name || ""}${
                                  item.oracle_customer_acc_number
                                    ? ` - ${item.oracle_customer_acc_number}`
                                    : ""
                                }`
                          )
                        );
                      })
                      .map((item) => ({
                        ...item,
                        customer_name: `${
                          item.ebs_customer_name || item.customer_name
                        }${
                          item.oracle_customer_acc_number
                            ? ` - ${item.oracle_customer_acc_number} - `
                            : ""
                        }${
                          item.address_line_1 ? `${item.address_line_1}` : ""
                        }${item.city ? `, ${item.city}` : ""}${
                          item.state_prov ? `, ${item.state_prov}` : ""
                        }${item.country ? `, ${item.country}` : ""}${
                          item.world_area ? `, ${item.world_area}` : ""
                        }${item.postal_code ? `, ${item.postal_code}` : ""}`,
                      })),
                    { customer_name: "OTHER" },
                  ]
            )}
            placeholder="PLEASE SELECT"
            warningMsg=""
            className="ServiceRequestText1"
          />
          <div className="dateDiv">
            <div>
              <span>
                <span className="field-required-span">*</span>
                <LabelText
                  text="PREFFERED SERVICE DATE"
                  textClass="ddl-from-custom-label app-content-label"
                />
              </span>
              <DatePicker
                dateFormat="MM/dd/yyyy"
                placeholderText="MM/DD/YYYY"
                selected={startDate}
                onChange={selectDateHandler}
                minDate={today}
                todayButton={"Today"}
                className="app-content-text-width   app-content-text-width-normals"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="ServiceRequestText2">
        {(selectedSite?.customer_name === "OTHER" ||
          selectedSite?.customer_name === "SITE NOT FOUND") && (
          <div className="endUserDetails">
            <p>Please provide a site address and any additional details.</p>
            <TextInput
              label="End User Company Name"
              onBlur={() => {}}
              onChange={(e) => {
                setEndUser(e.target.value);
              }}
              required = {selectedSite?.customer_name === "OTHER" ? true : false}
            />
            <TextInput
              label="End User Site Address"
              onBlur={() => {}}
              onChange={(e) => {
                setEndUserSiteAddress(e.target.value);
              }}
              required = {selectedSite?.customer_name === "OTHER" ? true : false}
            />
          </div>
        )}
        <TextInput
          label="ADDITIONAL DETAILS"
          onBlur={() => {}}
          onChange={(e) => {
            setAddDetails(e.target.value);
          }}
        />
      </div>
      <div>
        <br />
        <br />
        <p className="errorText">{errorSiteNotFoundMsg}</p>
        <ButtonInput
          btnType=""
          label="CONTINUE"
          onClick={() => {
            selectedSite.customer_name === "SITE NOT FOUND" ? setErrorMessage() : setActiveInStep(3);
          }}
          disabled={ 
            selectedSite === "" || 
            (selectedSite?.customer_name === "OTHER" && (endUser === "" || endUserSiteAddress === ""))
           }
        />
      </div>
    </div>
  );
};

export default ServiceRequestDetails;
